import React, { useState } from "react";
import {
  Container,
  Typography,
  Box,
  TextField,
  Button,
  Link,
  Grid,
} from "@mui/material";
import axios from "axios";

const SupportPage = () => {
  // Estados para manejar los datos del formulario
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  // Función para actualizar el estado cuando cambien los campos
  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = (event: any) => {
    event.preventDefault();
    alert("Tu mensaje ha sido enviado. Te responderemos pronto.");
    axios
      .post(`${process.env.REACT_APP_API_LINK}/api/support`, { ...formData })
      .then(() => {})
      .catch(() => {});
  };

  return (
    <Container maxWidth="md" sx={{ mt: 5, mb: 5 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Soporte de la Aplicación Un Bote
      </Typography>
      <Typography variant="body1" align="center" gutterBottom>
        Si tienes algún problema, comentario o solicitud de mejora para nuestra
        aplicación, no dudes en ponerte en contacto con nosotros.
      </Typography>

      {/* Información de contacto */}
      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" gutterBottom>
          Información de Contacto:
        </Typography>
        <Typography variant="body1">
          <strong>Dirección:</strong> Nueva California, Tierras Altas, Chiriqui,
          Panama
        </Typography>
        <Typography variant="body1">
          <strong>Correo Electrónico:</strong>{" "}
          <Link href="mailto:admin@unbote.app">admin@unbote.app</Link>
        </Typography>
        <Typography variant="body1">
          <strong>Teléfono:</strong>{" "}
          <Link href="tel:+50763816826">+507 6381 6826</Link>
        </Typography>
      </Box>

      {/* Formulario de contacto */}
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 4 }}>
        <Typography variant="h6" gutterBottom>
          Enviar un Mensaje
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              label="Nombre"
              fullWidth
              variant="outlined"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              label="Correo Electrónico"
              type="email"
              fullWidth
              variant="outlined"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              label="Mensaje"
              multiline
              rows={4}
              fullWidth
              variant="outlined"
              name="message"
              value={formData.message}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
        >
          Enviar
        </Button>
      </Box>
    </Container>
  );
};

export default SupportPage;
