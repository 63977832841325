import React from "react";
import { useState } from "react";
import styled from "styled-components";
import { termsData } from "../utils/privacy-policy";

const TermsContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const TitleText = styled.span`
  font-size: 24px;
  margin-bottom: 10px;
`;
const ParagraphText = styled.span`
  font-size: 16px;
  margin-bottom: 10px;
`;

const SubTitleText = styled.span`
  font-size: 20px;
  margin-bottom: 10px;
`;

export const TermsPage = () => {
  const [terms] = useState(termsData.es);

  console.log('HERE')

  return (
    <TermsContainer>
      {terms?.map((data: any, index: number) => (
        <React.Fragment key={index}>
          {data?.type === "bulletList" ? (
            data.content.map((content: any, index: number) => (
              <ParagraphText key={index}>- {content}</ParagraphText>
            ))
          ) : data?.type === "title" ? (
            <TitleText>{data.content}</TitleText>
          ) : data?.type === "paragraph" ? (
            <ParagraphText>{data.content}</ParagraphText>
          ) : (
            <SubTitleText>{data.content}</SubTitleText>
          )}
        </React.Fragment>
      ))}
    </TermsContainer>
  );
};
